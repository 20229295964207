@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', sans-serif;
}
button.chakra-button.add-user-btn, button.add-org-btn {
    background: #FFFFFF;
    border: 2px solid #616A72;
    border-radius: 6px;
    height: 46px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #383861;
    min-width: 122px;
}
button.chakra-button.add-user-btn:hover, button.add-org-btn:hover {
  background: #5f88db;
  border: 2px solid #0c44b4;
  color: #FFFFFF;
}
.header--wrap {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 9;
}
.header--wrap.css-a2tdk6{
  padding: 0 30px;
}
.sidebar---wrapper {
  padding: 20px 0;
}
.sidebar_icons-wrapper[role=group]:hover svg {
  color: #1a202c;
}
.sidebar_icons-wrapper{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #44474A;
}
.sidebar_icons-wrapper[role=group]{
  padding: 25px 15px; 
  width: 100%;
}
.chakra-stack.navbar-email-wrap {
  margin-top: 0;
}
h2.chakra-heading.header-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #23282C;
}
input.chakra-input.search-wrap {
    background: #FFFFFF;
    border: 1px solid #EBEAE9;
    border-radius: 6px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #333333;
    height: 40px;
}
form.user-search-form svg {
  width: 18px;
  height: 18px;
}
button.setting-dropdown-menu, button.logout-dropdown-menu {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #44474A;
  padding: 10px 18px;
  align-items: center;
}
.setting-dropdown-menu div, .logout-dropdown-menu div {
  padding: 0;
  align-items: center;
}
.setting-dropdown-menu svg, .logout-dropdown-menu svg {
  margin-top: 0;
  display: block;
  width: 100%;
  height: 100%;
  min-width: 20px;
  min-height: 20px;
}
.setting-dropdown-menu p.chakra-text, .logout-dropdown-menu p.chakra-text {
  margin-right: 10px;
}
div.chakra-menu__menu-list {
  padding: 0;
}
.chakra-menu__menu-list hr {
  border-color: #D9D9D9;
}
img.logo-wrap {
  max-width: 120px;
  object-fit: contain;
  height: 100%;
}
table.chakra-table th {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #605A54;
}
table.chakra-table  thead{
  background: #D2D6DE;
}
table.chakra-table td {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #605A54;
}
table.chakra-table td:first-child {
  font-weight: 600;
  text-transform: uppercase;
}
h2.chakra-heading.subheading-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #1A1B1F;
  margin-bottom: 8px;
}
button.chakra-button.edit-profile {
  height: 26px;
  background: rgba(86, 170, 195, 0.2);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #56AAC3;
}
p.setting-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #605A54;
}
h2.chakra-heading.css-lzf29c {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
/* or 82% */
  display: flex;
  align-items: center;
  color: #1A1B1F;
}

h2.chakra-heading.subheading-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
/* identical to box height, or 125% */
  color: #1A1B1F;
  margin-bottom: 8px;
}

button.chakra-button.edit-profile {
  height: 26px;
  background: rgba(86, 170, 195, 0.2);
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
/* identical to box height, or 167% */
  color: #56AAC3;
}

p.chakra-text.setting-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #605A54;
}
.chakra-stack.settings--wrapper.css-b5leqj {
  max-width: 1400px;
  margin: 0 auto;
}
.settings-content-wrapper {
  max-width: 900px;
}
.box-content--wrap > div {
  width: 100%;
  justify-content: space-between;
}
.profile-edit-form label,
.change-password-form label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #1A1B1F;
  margin-top: 0;
  margin-bottom: 10px;
}
.profile-edit-form input.chakra-input ,
.change-password-form input.chakra-input  {
  background: #FFFFFF;
  border: 1px solid #D2D6DE;
  height: 48px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px;
  color: #1A1B1F;
  margin-bottom: 25px;
}
.profile-edit-form button.chakra-button,
.change-password-form button.chakra-button {
  min-width: 148px;
}
.settings-content-wrapper .css-hboir5, .settings-content-wrapper  .box-content--wrap {
  border-bottom: 1px solid #EBEAE9;
}
.change-password-form .chakra-input__right-element{
  height: 48px;
}
button.edit-btn-wrapper {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  max-width: 170px;
  width: 100%;
}
button.chakra-button.button-wrap {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 26px;
  margin: 0 4px;
  border-radius: 6px;
  padding: 4px 12px;
}
button.chakra-button.auth-button.login-btn-wrap {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  height: 40px;
  background: #757575;
  border-radius: 8px;
  margin-bottom: 8px;
}
input#email, input#password {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #8B8B8B;
  height: 40px;
  box-shadow: none;
}
button.chakra-button a.forget-wrap {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 200%;
  text-align: right;
  letter-spacing: -0.02em;
  color: #AFAFAF;
}
p.chakra-text.have-account-wrap {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #AFAFAF;
}
.contact-support-wrap {
  margin-top: 11px;
}
h1.chakra-heading.css-1skdf69 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 55.5px;
  line-height: 74px;
  color: #FFFFFF;
}
h2.chakra-text.css-414j7b,
h2.chakra-text.css-zm3qx2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 163%;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-top: 14px;
}
.nav-bar--wrap.css-1qi9w9p {
  z-index: 9;
  position: sticky;
  position: -webkit-sticky;
}
select.chakra-select.select-org-wrap {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #23282C;
  box-shadow: none;
  height: 40px;
  margin-left: 22px;
}
button.chakra-button.link-btn.add-user-btn.css-xje8te {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #38606C;
  border: none;
  text-align: left;
  justify-content: flex-start;
}
.chakra-modal__body input, .chakra-modal__body select {
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #8B8B8B;
}
div.css-13nxndu {
  overflow-y: auto;
}
button.css-1c4uoxm:hover, button.css-1c4uoxm[data-hover] {
  background: none;
}
table.chakra-table td svg {
  width: 20px;
  height: 20px;
  margin-left: 18px;
}
hr.chakra-menu__divider.divider-wrap.css-12vdn2q {
  margin: 0;
}
button.chakra-button.send-invite-btn{
  background: #000000;
  border-radius: 8px;
  height: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
}
.org-option-wrap.css-0 {
  line-height: 42px;
}
p.chakra-text.css-1rf4y5g {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 170.23%;
  color: rgba(0, 0, 0, 0.4);
}
header.chakra-modal__header, div.chakra-modal__body {
  padding: 0;
}
.css-j1t0ts {
  border-radius: 4px;
}
p.chakra-text.css-t07pr6 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 25px;
  color: #8B8B8B;
}
div.chakra-modal__body {
  padding: 20px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 14%);
  border-radius: 17px;
}
.chakra-modal__body .css-nar6c1, section.chakra-modal__content{
  box-shadow: none;
  border-radius: 17px;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width:600px) {
  img.logo-wrap {
    max-width: 70px;
  }
  select.chakra-select.select-org-wrap {
    margin-left: 5px;
    max-width: max-content;
  }
  .header--wrap.css-a2tdk6 {
    padding: 0 8px;
  }  
}
@media only screen and (max-width:767px) {
  .sidebar---wrapper.css-1gividf {
    display: none;
  }
  .chakra-modal__content[aria-modal="true"] .sidebar---wrapper.css-1gividf {
    display: block;
  }
  .hambruger-menu-wrap.css-1x3k4jz {
    position: absolute;
    top: 0;
    z-index: 9;
    background: transparent;
    max-width: max-content;
  }
  .header--wrap img.logo-wrap {
    visibility: hidden;
    position: relative;
  }
  img.sidebar-logo-wrap.css-0 {
    max-width: 90px;
  }
  button.chakra-button.css-ahvdvk svg {
    stroke: #fff;
  }
  div.css-jfo9gg {
    padding: 0;
  }
  .right-content-wrapper p.chakra-text {
    /* position: absolute;
    bottom: 6px; */
    font-size: 11px;
    /* right: 0; */
    max-width: 60px;
  }
  .left-content-wrapper {
    width: 40%;
    justify-content: space-between;
  }
  .right-content-wrapper {
    width: 60%;
    justify-content: space-evenly;
  }

  .org-option-wrap.css-0 {
    text-align: right;
  }
}
.change-password-form .chakra-button.eye-button {
  min-width: auto;
}
.name-pointer:hover {
  cursor: pointer;
  color: #5f88db;
}

.hambruger-menu-wrap {
  position: absolute;
  top: 0;
  z-index: 9;
  background: transparent !important;
}
.hambruger-menu-wrap button{
  margin-right:10px;
}
.hambruger-menu-wrap svg {
  fill: #fff;
  stroke: #fff;
}
.right-content-wrapper{
  align-items: center;
}
.chakra-stack.email-wrapper {
  margin-top: 0;
}
button.chakra-menu__menu-button:hover {
  text-decoration: none;
}
input.chakra-input:focus-visible{
  border-color: none;
  box-shadow: none;
}
div.css-jfo9gg {
  padding: 0;
}
button.css-1ot399p:hover, button.css-1ot399p[data-hover] {
  background: #000;
}
.map-sidebar-wrap .chakra-input__group {
  /* border: 1px solid #333; */
  padding: 0;
}
@media only screen and (max-width:991px) {
  .organization--wrapper, .settings--wrapper,
  .userlist--wrapper {
  padding: 20px;
  }
}
@media only screen and (min-width:992px) {
  .organization--wrapper, .settings--wrapper,
  .userlist--wrapper {
  padding: 30px 40px;
  }
}
.table-title svg {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: top;
}