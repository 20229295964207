@media only screen and (max-width: 600px)  {
  .login-container{
    flex-direction: column;
  }
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 0; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.auth-button, .auth-input {
  background-color: #757575 !important;
  width: 100%;
  font-size: 12px !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
}

